import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import PartnershipsLayout from 'components/PartnershipsSite/PartnershipsLayout'
import VideoPlayerLite from 'components/VideoPlayerLite'
import { usePageView } from 'hooks'
import { getStaticWrapper, THEME, mapMediaDocToVideojsObject, formatWithCommas, darkenColor } from 'utils'
import { MediaStore } from 'stores/media'
import LazyLoad from 'react-lazyload'
import MediaQuery from 'react-responsive'

const SectionTemplate = ({ header, subheader, buttonLink, background = '#fff', styles, children }) => {
  return (
    <div className='section' style={{ ...styles, background }}>
      {header && <h1>{header}</h1>}
      {subheader && <h5>{subheader}</h5>}
      {children}
      {buttonLink && (
        <Link href={buttonLink.href} legacyBehavior>
          <a>
            <button className='btn'>{buttonLink.text}</button>
          </a>
        </Link>
      )}
      <style jsx>{`
        .section {
          padding: 40px 0;
          width: 100%;
        }
        .section h1 {
          color: #141e31;
          text-align: center;
          width: 90%;
          max-width: 960px;
          font-size: 1.45rem;
          line-height: 1.1;
          margin: 0 auto ${subheader ? '0' : '25px'};
        }
        .section h5 {
          color: darkgray;
          text-align: center;
          width: 100%;
          margin: 5px 0 25px;
          font-size: 0.75rem;
          max-width: 900px;
        }
        .section .btn {
          display: block;
          width: 200px;
          margin: 50px auto 0;
          padding-left: 0px;
          padding-right: 0px;
          background-color: #c62232;
        }
        .section .btn:hover {
          background-color: ${darkenColor('#C62232')};
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          .section h1 {
            font-size: 1.5rem;
            margin: 0 auto ${subheader ? '0' : '10px'};
          }
          .section h5 {
            font-size: 0.8rem;
            margin: 0 auto 10px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .section {
            padding: 40px 0 60px;
          }
          .section h1 {
            line-height: 1.4;
            font-size: 1.8rem;
            margin: 0 auto ${subheader ? '0' : '25px'};
          }
          .section h5 {
            font-size: 0.85rem;
            margin: 0 auto 25px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .section {
            padding: 50px 0 70px;
          }
          .section h1 {
            font-size: 2rem;
            margin: 0 auto ${subheader ? '0' : '40px'};
          }
          .section h5 {
            font-size: 0.9rem;
            margin: 0 auto 40px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.LG}px) {
          .section {
            padding: 60px 0 90px;
          }
          .section h1 {
            font-size: 2.2rem;
            margin: 0 auto ${subheader ? '0' : '45px'};
          }
          .section h5 {
            font-size: 0.95rem;
            margin: 0 auto 45px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XL}px) {
          .section {
            padding: 80px 0 110px;
          }
          .section h1 {
            font-size: 2.25rem;
          }
          .section h5 {
            font-size: 1rem;
          }
        }
      `}</style>
    </div>
  )
}

const SectionBanner = ({ text }) => {
  return (
    <div className='sectionBanner'>
      <h1>{text}</h1>
      <style jsx>{`
        .sectionBanner {
          background-color: #ea3d3e;
          padding: 75px 0;
        }
        .sectionBanner h1 {
          font-size: 2.1rem;
          color: #fff;
          text-align: center;
        }
        @media (max-width: ${THEME.BREAKPOINTS.XS}px) {
          .sectionBanner {
            padding: 30px 0;
          }
          .sectionBanner h1 {
            font-size: 1.5rem;
          }
        }
      `}</style>
    </div>
  )
}

const CountUpAnimation = ({ start = 0, end, decimals = 0, duration = 2000, delay = 0 }) => {
  const [count, setCount] = useState(start)

  useEffect(() => {
    setTimeout(() => {
      let frame = 0
      const totalFrames = Math.round(duration / (1000 / 60))
      const counter = setInterval(() => {
        frame++
        const p = frame / totalFrames
        const progress = p * (2 - p)
        setCount(end * progress)

        if (frame === totalFrames) {
          clearInterval(counter)
        }
      }, 1000 / 60)
    }, delay)
  }, [])

  return formatWithCommas(count, decimals)
}

const NumCard = ({ num, numText, text, decimals, textColor = '#fff' }) => {
  const lazyWrapperStyles = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }

  return (
    <>
      <LazyLoad style={lazyWrapperStyles} once offset={150}>
        <h2 className='numCard__number'>
          <CountUpAnimation duration={1000} end={num} decimals={decimals} />
          {numText}
        </h2>
        <hr className='numCard__line' />
        <h4 className='numCard__text'>{text}</h4>
      </LazyLoad>
      <style jsx>{`
        .numCard__number {
          color: ${THEME.COLORS.PRIMARY};
          margin: 0;
          font-size: 1.85rem;
          width: 100%;
          text-align: center;
        }
        .numCard__line {
          background: #fff;
          width: 0%;
          margin: 10px 0;
          justify-self: flex-start;
          animation-name: slidetoright;
          animation-delay: 0.5s;
          animation-duration: 1s;
          animation-fill-mode: forwards;
          animation-timing-function: ease-in-out;
        }
        .numCard__text {
          text-align: center;
          color: ${textColor};
          margin: 0;
          transform: translateY(40px);
          font-weight: 500;
          font-size: 0.6rem;
          opacity: 0;
          animation-name: fadeup;
          animation-delay: 0.5s;
          animation-duration: 1s;
          animation-fill-mode: forwards;
          animation-timing-function: ease-out;
        }
        @keyframes slidetoright {
          0% {
            width: 0%;
          }
          100% {
            width: 100%;
          }
        }
        @keyframes fadeup {
          0% {
            transform: translateY(40px);
            opacity: 0;
          }
          100% {
            transform: translateY(0px);
            opacity: 1;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          .numCard__number {
            font-size: 1.5rem;
          }
          .numCard__line {
            margin: 10px 0;
          }
          .numCard__text {
            font-size: 0.5rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .numCard__number {
            font-size: 1.75rem;
          }
          .numCard__text {
            font-size: 0.55rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .numCard__number {
            font-size: 2.25rem;
          }
          .numCard__text {
            font-size: 0.75rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XL}px) {
          .numCard__number {
            font-size: 2.9rem;
          }
          .numCard__line {
            margin: 16px 0;
          }
          .numCard__text {
            font-size: 0.85rem;
          }
        }
      `}</style>
    </>
  )
}

const Section2Info = ({ highlight, text, source, time }) => {
  return (
    <div className={`s2__info slidein`}>
      <h2>
        <span>{highlight}</span>&nbsp;
        {text.map((t, idx) => (
          <span key={idx}>
            {t}
            <MediaQuery maxWidth={THEME.BREAKPOINTS.XS - 1}>
              <br />
            </MediaQuery>
            <MediaQuery minWidth={THEME.BREAKPOINTS.XS}>&nbsp;</MediaQuery>
          </span>
        ))}
      </h2>
      <h6>{source}</h6>
      <style jsx>{`
        .s2__info {
          width: auto;
          padding: 20px 0;
          border-bottom: 1px solid ${THEME.COLORS.LIGHTERGRAY};
        }
        .s2__info:last-of-type {
          border-bottom: none;
        }
        .s2__info h2 {
          text-align: center;
          font-size: 1.25rem;
          margin: 0;
        }
        .s2__info span:first-of-type {
          color: ${THEME.COLORS.PRIMARY};
        }
        .s2__info h6 {
          text-align: center;
          font-size: 0.75rem;
          margin: 0;
          color: #999999;
          font-style: italic;
          font-weight: 500;
        }
        .slidein {
          opacity: 0;
          animation-name: slidein;
          animation-delay: ${time}s;
          animation-duration: 0.75s;
          animation-fill-mode: forwards;
          animation-timing-function: ease-out;
        }
        @keyframes slidein {
          0% {
            opacity: 0;
            transform: translateX(120px);
          }
          100% {
            opacity: 1;
            transform: translateX(0);
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          .s2__info {
            padding: 16px 0 16px 6px;
          }
          .s2__info h2 {
            font-size: 1rem;
            text-align: left;
          }
          .s2__info h6 {
            margin-top: 2px;
            font-size: 0.7rem;
            text-align: left;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .s2__info {
            padding: 18px 0 18px 6px;
          }
          .s2__info h2 {
            font-size: 1.2rem;
          }
          .s2__info h6 {
            font-size: 0.75rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .s2__info {
            padding: 20px 0 20px 6px;
          }
          .s2__info h2 {
            font-size: 1.4rem;
          }
          .s2__info h6 {
            font-size: 0.8rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.LG}px) {
          .s2__info {
            padding: 22px 0 22px 6px;
          }
          .s2__info h2 {
            font-size: 1.6rem;
          }
          .s2__info h6 {
            font-size: 0.85rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XL}px) {
          .s2__info h2 {
            font-size: 1.7rem;
          }
          .s2__info h6 {
            font-size: 0.95rem;
          }
        }
      `}</style>
    </div>
  )
}

const Section2 = () => {
  const icons = [
    'https://chumley.barstoolsports.com/union/2021/02/02/barstool_icon.85479689.png',
    'https://chumley.barstoolsports.com/union/2021/02/02/snapchat.1ebb2279.png',
    'https://chumley.barstoolsports.com/union/2021/02/02/facebook.384e7f8d.png',
    'https://chumley.barstoolsports.com/union/2021/02/02/instagram.291749e7.png',
    'https://chumley.barstoolsports.com/union/2021/02/02/twitter.7deabcfc.png',
    'https://chumley.barstoolsports.com/union/2021/02/02/tik_tok.e6c688ff.png',
    'https://chumley.barstoolsports.com/union/2021/02/02/twitch.f29876be.png',
    'https://chumley.barstoolsports.com/union/2021/02/02/youtube.a2d3c064.png',
    'https://chumley.barstoolsports.com/union/2021/02/09/ClubHouse-Logo-696x387-1.31c73870.jpg',
    'https://chumley.barstoolsports.com/union/2021/02/02/pandora.eae8d2d3.png',
    'https://chumley.barstoolsports.com/union/2021/02/02/itunes.1216ff34.png',
    'https://chumley.barstoolsports.com/union/2021/02/02/apple_tv.6b267990.png',
    'https://chumley.barstoolsports.com/union/2021/02/02/android_tv.35747ab3.png',
    'https://chumley.barstoolsports.com/union/2021/02/02/fire_tv.dfba198e.png',
    'https://chumley.barstoolsports.com/union/2021/02/02/roku.4d22a0e3.png',
    'https://chumley.barstoolsports.com/union/2021/02/02/spotify.7c216f33.png'
  ]

  const info = [
    {
      highlight: '#1',
      text: ['most engaged digital-first sports media creator'],
      source: 'Shareablee, Sept 2022'
    },
    {
      highlight: 'Top 10',
      text: ['Podcast Publisher'],
      source: 'Podtrac, Sept 2022'
    },
    {
      highlight: '3rd Most',
      text: ['Engaged Sports Publisher on Social'],
      source: 'Shareablee, Sept 2022 '
    }
  ]

  return (
    <SectionTemplate header='You Can Find Us Everywhere'>
      <div className='s2__content'>
        <div className='s2__icons'>
          {icons.map((icon, idx) => (
            <img key={idx} src={`${icon}?format=png&width=100&height=100`} alt={icon} />
          ))}
        </div>
        <div className='s2__info'>
          <LazyLoad once offset={150}>
            {info.map((i, idx) => (
              <Section2Info key={idx} {...i} time={idx * 1} />
            ))}
          </LazyLoad>
        </div>
      </div>
      <style jsx>{`
        .s2__content {
          width: 90%;
          max-width: 1200px;
          margin: auto;
          display: flex;
          flex-direction: column;
        }
        .s2__icons {
          width: auto;
          margin: auto;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-template-rows: auto;
          justify-items: center;
          column-gap: 20px;
          row-gap: 20px;
        }
        .s2__icons img {
          width: 50px;
          border-radius: 20%;
          transition: 0.5s;
        }
        .s2__info {
          width: 90%;
          margin: 15px auto;
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          .s2__content {
            width: 540px;
            flex-direction: row;
          }
          .s2__icons {
            column-gap: 12px;
            row-gap: 12px;
          }
          .s2__icons img {
            width: 35px;
          }
          .s2__info {
            width: 60%;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .s2__content {
            width: 610px;
            justify-content: space-between;
          }
          .s2__icons {
            margin: auto 0;
            column-gap: 15px;
            row-gap: 15px;
          }
          .s2__icons img {
            width: 40px;
          }
          .s2__info {
            margin: auto 0;
            width: 60%;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .s2__content {
            width: 700px;
          }
          .s2__icons img {
            width: 45px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.LG}px) {
          .s2__content {
            width: 785px;
          }
          .s2__icons {
            column-gap: 16px;
            row-gap: 16px;
          }
          .s2__icons img {
            width: 50px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XL}px) {
          .s2__content {
            width: 875px;
          }
          .s2__icons {
            column-gap: 18px;
            row-gap: 18px;
          }
          .s2__icons img {
            width: 52px;
          }
        }
      `}</style>
    </SectionTemplate>
  )
}

const Section3 = () => {
  const info = [
    {
      num: 198,
      numText: 'M+',
      text: 'Social Followers'
    },
    {
      num: 1.7,
      numText: 'K+',
      text: 'Social Accounts',
      decimals: 1
    },
    {
      num: 4.8,
      numText: 'B+',
      text: 'Monthly Video Views',
      decimals: 1
    }
  ]

  return (
    <div className='s3'>
      <div className='s3__overlay' />
      <LazyLoad once style={{ zIndex: 2 }}>
        <div className='s3__text'>
          {['AUTHENTIC.', 'UNFILTERED.', '24/7.'].map((text, idx) => (
            <h1 key={idx} style={{ animationDelay: `${0.5 * (idx + 1)}s` }}>
              {text}
            </h1>
          ))}
        </div>
      </LazyLoad>
      <div className='s3__flex'>
        {info.map((infoProps, idx) => (
          <div key={idx} className='s3__card'>
            <NumCard {...infoProps} />
          </div>
        ))}
      </div>
      <style jsx global>{`
        .s3 {
          padding: 10% 0 6%;
          background-image: url('https://chumley.barstoolsports.com/union/2021/05/06/viewing-2.ad8eb46c.png?width=2000');
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          height: 425px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
        }
        .s3__text {
          display: flex;
          flex-direction: column;
        }
        .s3__text h1 {
          color: #ffffff;
          text-align: center;
          font-size: 2.25rem;
          margin: 0;
          opacity: 0;
          animation-name: fadeInOpacity;
          animation-fill-mode: forwards;
          animation-timing-function: ease-in;
          animation-duration: 0.75s;
        }
        .s3__text h1:nth-child(2) {
          margin: 5px 0;
        }
        .s3__flex {
          z-index: 2;
          display: flex;
          flex-wrap: wrap;
          width: 90%;
          justify-content: space-evenly;
        }
        .s3__card {
          margin: 0 20px;
          width: 30%;
          margin-bottom: 20px;
        }
        .s3__overlay {
          z-index: 1;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: rgb(2, 0, 36);
          background: -moz-linear-gradient(
            45deg,
            rgba(2, 0, 36, 0.4023984593837535) 0%,
            rgba(0, 0, 0, 0.1390931372549019) 100%,
            rgba(0, 212, 255, 1) 100%
          );
          background: -webkit-linear-gradient(
            45deg,
            rgba(2, 0, 36, 0.4023984593837535) 0%,
            rgba(0, 0, 0, 0.1390931372549019) 100%,
            rgba(0, 212, 255, 1) 100%
          );
          background: linear-gradient(
            45deg,
            rgba(2, 0, 36, 0.4023984593837535) 0%,
            rgba(0, 0, 0, 0.1390931372549019) 100%,
            rgba(0, 212, 255, 1) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#00d4ff",GradientType=1);
        }
        @keyframes fadeInOpacity {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @media only screen and (max-device-width: 1366px) {
          .s3 {
            background-attachment: scroll;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          .s3 {
            padding: 50px 0 30px;
            height: 350px;
            background-attachment: fixed;
          }
          .s3__flex {
            width: 520px;
            wrap: no-wrap;
          }
          .s3__card {
            width: 17%;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .s3 {
            padding: 60px 0 40px;
            height: 390px;
            background-attachment: fixed;
          }
          .s3__text h1 {
            font-size: 2.5rem;
          }
          .s3__text h1:nth-child(2) {
            margin: 12px 0;
          }
          .s3__flex {
            width: 590px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .s3 {
            padding: 75px 0 55px;
            height: 450px;
          }
          .s3__flex {
            width: 720px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.LG}px) {
          .s3 {
            padding: 90px 0 65px;
            height: 530px;
          }
          .s3__text h1 {
            font-size: 3rem;
          }
          .s3__text h1:nth-child(2) {
            margin: 25px 0;
          }
          .s3__flex {
            width: 800px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XL}px) {
          .s3 {
            padding: 100px 0 70px;
            height: 580px;
          }
          .s3__flex {
            width: 900px;
          }
          .s3__text h1 {
            font-size: 3.5rem;
          }
          .s3__text h1:nth-child(2) {
            margin: 35px 0;
          }
        }
        @media (min-width: 1400px) {
          .s3 {
            padding: 150px 0 100px;
            height: 700px;
          }
          .s3__flex {
            width: 900px;
          }
          .s3__text h1 {
            font-size: 4rem;
          }
          .s3__text h1:nth-child(2) {
            margin: 40px 0;
          }
        }
      `}</style>
    </div>
  )
}

const Circle = ({ percent, width, strokeColor = '#141E31', gender = false }) => {
  const radius = 60
  const stroke = 20
  const circumference = radius * 2 * Math.PI
  const [dashoffset, setDashoffset] = useState(circumference)

  useEffect(() => {
    const offset = circumference - (percent / 100) * circumference
    setDashoffset(offset)
  }, [circumference, percent])

  const sharedCircleProps = {
    fill: 'transparent',
    strokeWidth: stroke,
    r: radius,
    cx: '50%',
    cy: '50%'
  }

  return (
    <div className='pr'>
      <svg viewBox='0 0 140 140' preserveAspectRatio='xMinYMin meet' className='progress-ring'>
        <circle className='progress-ring__circle' stroke='#F5F5F5' strokeDashoffset={0} {...sharedCircleProps} />
        <circle
          className='progress-ring__circle'
          stroke={strokeColor}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={dashoffset}
          {...sharedCircleProps}
        />
      </svg>
      <div className='progress-ring__inside'>
        {gender ? (
          <>
            <img
              src={`https://chumley.barstoolsports.com/union/2021/02/02/${
                gender === 'male' ? 'male-icon.5db02840' : 'female-icon.3de617d2'
              }.png`}
              alt={gender}
            />
            <h4>
              <CountUpAnimation end={percent} duration={4000} delay={500} />
              <span>%</span>
            </h4>
          </>
        ) : (
          <>
            <h2>
              <CountUpAnimation end={percent} duration={4000} delay={500} />
              <span>%</span>
            </h2>
            <h5>AGE 18-34</h5>
          </>
        )}
      </div>
      <style jsx>{`
        .pr {
          position: relative;
          width: ${width};
          margin: auto;
        }
        .progress-ring {
          margin: 0 auto;
          display: block;
        }
        .progress-ring circle {
          transform: rotate(90deg);
          transform-origin: 50% 50%;
        }
        .progress-ring circle:nth-child(2) {
          transition: stroke-dashoffset 4.5s;
          transition-delay: 0.5s;
        }
        .progress-ring__inside {
          position: absolute;
          color: ${strokeColor};
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .progress-ring__inside h2 {
          margin: 0;
          font-size: 3.6rem;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: flex-start;
        }
        .progress-ring__inside h2 span {
          font-size: 1.5rem;
          margin-top: 20%;
        }
        .progress-ring__inside h5 {
          margin: 0;
          font-size: 1rem;
          text-align: center;
        }
        .progress-ring__inside img {
          height: 25px;
        }
        .progress-ring__inside h4 {
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: flex-start;
        }
        .progress-ring__inside h4 span {
          font-size: 0.6rem;
          margin-top: 20%;
        }
        @media (max-width: ${THEME.BREAKPOINTS.XL}px) {
          .progress-ring__inside h2 {
            font-size: 3.25rem;
          }
          .progress-ring__inside h2 span {
            font-size: 1.25rem;
          }
          .progress-ring__inside h5 {
            font-size: 0.75rem;
          }
          .progress-ring__inside img {
            height: 18px;
          }
          .progress-ring__inside h4 {
            font-size: 0.9rem;
          }
          .progress-ring__inside h4 span {
            font-size: 0.4rem;
          }
        }
        @media (max-width: ${THEME.BREAKPOINTS.LG}px) {
          .progress-ring__inside h2 {
            font-size: 2.9rem;
          }
          .progress-ring__inside h2 span {
            font-size: 1.15rem;
          }
          .progress-ring__inside h5 {
            font-size: 0.7rem;
          }
          .progress-ring__inside img {
            height: 16px;
          }
          .progress-ring__inside h4 {
            font-size: 0.8rem;
          }
          .progress-ring__inside h4 span {
            font-size: 0.4rem;
          }
        }
        @media (max-width: ${THEME.BREAKPOINTS.MD}px) {
          .progress-ring__inside h2 {
            font-size: 2.7rem;
          }
          .progress-ring__inside h2 span {
            font-size: 0.9rem;
          }
          .progress-ring__inside h5 {
            font-size: 0.6rem;
          }
          .progress-ring__inside img {
            height: 14px;
          }
          .progress-ring__inside h4 {
            font-size: 0.7rem;
          }
          .progress-ring__inside h4 span {
            font-size: 0.35rem;
          }
        }
        @media (max-width: ${THEME.BREAKPOINTS.SM}px) {
          .progress-ring__inside h2 {
            font-size: 2rem;
          }
          .progress-ring__inside h2 span {
            font-size: 0.75rem;
          }
          .progress-ring__inside h5 {
            font-size: 0.55rem;
          }
          .progress-ring__inside img {
            height: 14px;
          }
          .progress-ring__inside h4 {
            font-size: 0.7rem;
          }
          .progress-ring__inside h4 span {
            font-size: 0.25rem;
          }
        }
        @media (max-width: ${THEME.BREAKPOINTS.XS}px) {
          .progress-ring__inside h2 {
            font-size: 2.7rem;
          }
          .progress-ring__inside h2 span {
            font-size: 0.9rem;
          }
          .progress-ring__inside h5 {
            font-size: 0.6rem;
          }
          .progress-ring__inside img {
            height: 14px;
          }
          .progress-ring__inside h4 {
            font-size: 0.7rem;
          }
          .progress-ring__inside h4 span {
            font-size: 0.35rem;
          }
        }
      `}</style>
    </div>
  )
}

const Section4 = () => {
  const circlePercents = [
    {
      title: 'O&O',
      age: 55,
      male: 77,
      female: 23
    },
    {
      title: 'Social',
      age: 66,
      male: 69,
      female: 31
    },
    {
      title: 'Audio',
      age: 85,
      male: 84,
      female: 16
    },
    {
      title: 'YouTube',
      age: 62,
      male: 87,
      female: 13
    }
  ]

  return (
    <SectionTemplate header={'We Reach Different Audiences Across Different Platforms'}>
      <LazyLoad height={'400px'} style={{ width: '95%', maxWidth: '1200px', margin: 'auto' }} once>
        <div className='s4__flex'>
          {circlePercents.map(({ title, age, male, female }, idx) => (
            <div className='s4__circle' key={idx}>
              <h3>{title}</h3>
              <Circle percent={age} width={'100%'} />
              <div style={{ display: 'flex', marginTop: '15px' }}>
                <Circle gender={'male'} percent={male} width={'40%'} />
                <Circle gender={'female'} percent={female} width={'40%'} strokeColor={'red'} />
              </div>
            </div>
          ))}
        </div>
      </LazyLoad>
      <style jsx>{`
        .s4__flex {
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          margin: auto;
          max-width: 1100px;
        }
        .s4__circle {
          width: 18%;
        }
        .s4__circle h3 {
          margin: 0 0 15px;
          font-size: 1.4rem;
          width: 100%;
          text-align: center;
        }
        @media (max-width: ${THEME.BREAKPOINTS.XL}px) {
          .s4__circle h3 {
            font-size: 1.25rem;
          }
        }
        @media (max-width: ${THEME.BREAKPOINTS.LG}px) {
          .s4__circle h3 {
            font-size: 1.25rem;
          }
        }
        @media (max-width: ${THEME.BREAKPOINTS.MD}px) {
          .s4__circle h3 {
            font-size: 1.25rem;
          }
        }
        @media (max-width: ${THEME.BREAKPOINTS.SM}px) {
          .s4__circle h3 {
            font-size: 1.1rem;
          }
        }
        @media (max-width: ${THEME.BREAKPOINTS.XS}px) {
          .s4__flex {
            flex-wrap: wrap;
          }
          .s4__circle {
            width: 140px;
            margin-bottom: 40px;
          }
          .s4__circle h3 {
            font-size: 1rem;
            margin: 0 0 10px;
          }
        }
      `}</style>
    </SectionTemplate>
  )
}

const Section5 = () => {
  const info = [
    {
      num: 110,
      numText: '+',
      text: 'Personalities'
    },
    {
      num: 65,
      numText: '+',
      text: 'Franchises'
    },
    {
      num: 17,
      numText: '+',
      text: 'Content Verticals'
    },
    {
      num: 95,
      numText: '+',
      text: 'Shows'
    }
  ]

  return (
    <SectionTemplate header={'Our Program Is Driven By Our Personalities'}>
      <div className='s5__flex'>
        {info.map((infoProps, idx) => (
          <div className='s5__card' key={idx}>
            <NumCard {...infoProps} textColor='#141e31' />
          </div>
        ))}
      </div>
      <style jsx>{`
        .s5__flex {
          display: flex;
          justify-content: space-evenly;
          width: 72%;
          max-width: 800px;
          margin: 0 auto;
        }
        .s5__card {
          width: 18%;
        }
        @media (max-width: ${THEME.BREAKPOINTS.XS}px) {
          .s5__flex {
            width: 95%;
            flex-wrap: wrap;
            justify-content: space-between;
          }
          .s5__card {
            width: 20%;
          }
        }
      `}</style>
    </SectionTemplate>
  )
}

const Section6 = () => {
  const verticalIcons = [
    {
      name: 'Behind Barstool',
      link: 'behind-barstool',
      image: 'https://chumley.barstoolsports.com/union/2021/02/10/daily.f7250b8c.6ab96b18.jpg'
    },
    {
      name: 'El Presidente',
      link: 'el-presidente',
      image: 'https://chumley.barstoolsports.com/union/2021/02/02/el_pres.ea6e5df4.png'
    },
    {
      name: 'Barstool Football',
      link: 'barstool-football',
      image: 'https://chumley.barstoolsports.com/union/2021/02/02/football.052a19e3.png'
    },
    {
      name: 'Barstool Baseball',
      link: 'barstool-baseball',
      image: 'https://chumley.barstoolsports.com/union/2021/02/02/baseball.86b35ed1.png'
    },
    {
      name: 'Barstool Golf',
      link: 'barstool-golf',
      image: 'https://chumley.barstoolsports.com/union/2021/02/02/golf.0f97daca.png'
    },
    {
      name: 'Barstool Hockey',
      link: 'barstool-hockey',
      image: 'https://chumley.barstoolsports.com/union/2021/02/02/hockey.2c6f8ee0.png'
    },
    {
      name: 'Barstool Combat',
      link: 'barstool-combat',
      image: 'https://chumley.barstoolsports.com/union/2021/02/02/combat.321554a6.png'
    },
    {
      name: 'Barstool Soccer',
      link: 'barstool-soccer',
      image: 'https://chumley.barstoolsports.com/union/2021/02/02/soccer.0e1a4c97.png'
    },
    {
      name: 'Barstool Sportsbook',
      link: 'barstool-sportsbook',
      image: 'https://chumley.barstoolsports.com/union/2021/02/02/sportsbook.9f4d782d.png'
    },
    {
      name: 'Barstool Lifestyle & Entertainment',
      link: 'barstool-lifestyle-entertainment',
      image: 'https://chumley.barstoolsports.com/union/2021/02/02/lifestyle.bb77339e.png'
    },
    {
      name: 'Barstool Travel & Outdoors',
      link: 'barstool-travel-outdoors',
      image: 'https://chumley.barstoolsports.com/union/2021/02/02/outdoors.a0c39cf2.png'
    },
    {
      name: 'Barstool Gaming',
      link: 'barstool-gaming',
      image: 'https://chumley.barstoolsports.com/union/2021/02/02/gaming.474ca992.png'
    },
    {
      name: 'Female Lifestyle',
      link: 'barstool-female-lifestyle',
      image: 'https://chumley.barstoolsports.com/union/2021/02/02/female.8356ac2e.png'
    },
    {
      name: 'Barstool Business & News',
      link: 'barstool-business-news',
      image: 'https://chumley.barstoolsports.com/union/2021/02/02/business.7d0d5851.png'
    },
    {
      name: 'Barstool Cities',
      link: 'barstool-cities',
      image: 'https://chumley.barstoolsports.com/union/2021/02/02/chicago.4ef88eb0.png'
    },
    {
      name: 'Barstool Military',
      link: 'barstool-military',
      image: 'https://chumley.barstoolsports.com/union/2021/02/02/military.f0ac40e5.png'
    },
    {
      name: 'Barstool Basketball',
      link: 'barstool-basketball',
      image: 'https://chumley.barstoolsports.com/union/2021/02/02/basketball.e51cb71c.png'
    },
    {
      name: 'Barstool Food',
      link: 'barstool-food',
      image: 'https://chumley.barstoolsports.com/union/2021/02/09/Food.a90834e8.png'
    }
  ]

  return (
    <SectionTemplate
      header={'We Are More Than Just Sports'}
      subheader={'We create content across a variety of categories'}
      buttonLink={{
        href: 'partnerships/verticals',
        text: 'See All Verticals'
      }}
    >
      <div className='s6__grid'>
        {verticalIcons.map(({ name, link, image }, idx) => (
          <Link key={idx} href={`partnerships/verticals/${link}`} legacyBehavior>
            <a className='vertical'>
              <img src={image} alt={name} />
              <h6>{name}</h6>
            </a>
          </Link>
        ))}
      </div>
      <style jsx>{`
        .s6__grid {
          width: 90%;
          max-width: 1000px;
          margin: 0 auto;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
          grid-gap: 10px;
        }
        .s6__grid img {
          width: 100%;
          height: 100%;
        }
        .vertical {
          max-height: 160px;
          position: relative;
          transition: 0.5s;
        }
        .vertical:hover {
          transform: scale(0.95);
        }
        .vertical h6 {
          position: absolute;
          margin: 0 auto;
          top: 35%;
          left: 0;
          right: 0;
          color: #fff;
          font-size: 1rem;
          width: 70%;
          text-align: center;
          transition: 0.5s;
        }
        .vertical:hover h6 {
          transform: scale(1.15);
        }
        @media (max-width: ${THEME.BREAKPOINTS.MD}px) {
          .vertical h6 {
            font-size: 0.8rem;
            width: 90%;
          }
        }
        @media (max-width: ${THEME.BREAKPOINTS.XS}px) {
          .s6__grid {
            grid-template-columns: 1fr 1fr 1fr;
          }
          .vertical h6 {
            top: 30%;
            width: 90%;
          }
          .vertical:hover,
          .vertical:hover h6 {
            transform: none;
          }
        }
      `}</style>
    </SectionTemplate>
  )
}

const Section7 = () => {
  const info = [
    {
      img: 'https://chumley.barstoolsports.com/union/2021/05/06/Programming_Shows.04562701.png',
      title: 'Podcast Shows',
      details: "Some of our brand's biggest shows that are not only audio, but have large video and social audiences"
    },
    {
      img: 'https://chumley.barstoolsports.com/union/2021/05/06/StudioShows.f7be8e7c.png',
      title: 'Studio Shows',
      details: "Barstool's biggest talent on-set producing appointment viewing content spanning sports-talk to trivia"
    },
    {
      img: 'https://chumley.barstoolsports.com/union/2021/05/06/Programming_LiveStreams.d1b60087.png',
      title: 'Live Streams',
      details: "Our talent's live reactions to big sports or pop culture moments"
    },
    {
      img: 'https://chumley.barstoolsports.com/union/2021/05/06/Programming_BehindTheScenes.3e6f940c.png',
      title: 'Behind the Scenes Programming',
      details: 'Reality-style promgramming giving viewers a glimpse inside Barstool, our Franchises, and personalities'
    },
    {
      img: 'https://chumley.barstoolsports.com/union/2021/05/06/Programming_SocialSeries.f6c4c635.png',
      title: 'Social Series',
      details: 'Recurring short-form programming'
    },
    {
      img: 'https://chumley.barstoolsports.com/union/2021/05/06/Programming_247Social.927f67a7.png',
      title: '24/7 Social',
      details: 'Viral and original content across thousands of social accounts'
    }
  ]

  return (
    <SectionTemplate>
      <LazyLoad once>
        <div className='s7__grid'>
          {info.map(({ img, title, details }, idx) => (
            <div
              className='s7__info fadein'
              key={idx}
              style={{ backgroundImage: `url('${img}')`, animationDelay: `${0.25 * (idx + 1)}s` }}
            >
              <h2>{title}</h2>
              <h5>{details}</h5>
            </div>
          ))}
        </div>
      </LazyLoad>
      <style jsx>{`
        .s7__grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-auto-rows: 1fr;
          grid-gap: 10px;
          justify-content: center;
          margin: 0 auto;
          width: 90%;
          max-width: 1000px;
        }
        .s7__info {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          color: #fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 50% 8% 8%;
        }
        .s7__info h2 {
          width: 100%;
          margin: 0;
        }
        .s7__info h5 {
          width: 90%;
          align-self: flex-start;
          margin: 5px 0 0;
          font-size: 0.8rem;
          line-height: 1.5;
        }
        .fadein {
          opacity: 0;
          animation-name: fadeInOpacity;
          animation-fill-mode: forwards;
          animation-timing-function: ease-in;
          animation-duration: 0.75s;
        }
        @keyframes fadeInOpacity {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @media (max-width: ${THEME.BREAKPOINTS.MD}px) {
          .s7__info h2 {
            font-size: 1.1rem;
          }
          .s7__info h5 {
            font-size: 0.7rem;
            width: 95%;
          }
        }
        @media (max-width: ${THEME.BREAKPOINTS.XS}px) {
          .s7__grid {
            grid-template-columns: repeat(2, 1fr);
            width: 95%;
          }
          .s7__info:nth-child(3) {
            grid-column: 2/3;
            grid-row: 2/3;
          }
          .s7__info:nth-child(4) {
            grid-column: 1/2;
            grid-row: 2/3;
          }
          .s7__info h2 {
            font-size: 0.95rem;
          }
          .s7__info h5 {
            font-size: 0.55rem;
            line-height: 1.5;
          }
        }
      `}</style>
    </SectionTemplate>
  )
}

const Section8 = () => {
  const info = [
    {
      img: 'https://chumley.barstoolsports.com/union/2021/05/06/image-3.5234a214.png',
      title: 'Reach',
      details: 'Unmatched reach among Millenial and Gen-Z audiences'
    },
    {
      img: 'https://chumley.barstoolsports.com/union/2021/05/06/image-4.68ee8e70.png',
      title: 'Influence',
      details: 'Our passionate fans convert'
    },
    {
      img: 'https://chumley.barstoolsports.com/union/2021/05/06/image-5.c068d430.png',
      title: 'We editorialize brands',
      details: 'Our endorsement is real and authentic'
    }
  ]

  return (
    <SectionTemplate styles={{ padding: '50px 0' }}>
      <h4>Reach our fans to make them your fans</h4>
      <LazyLoad once>
        <div className='s8__flex'>
          {info.map(({ img, title, details }, idx) => (
            <div key={idx} className='s8__info' style={{ animationDelay: `${0.25 * (idx + 1)}s` }}>
              <span>
                <img src={img} alt={'s8__img ' + idx} />
              </span>
              <div>
                <h3>{title}</h3>
                <h5>{details}</h5>
              </div>
            </div>
          ))}
        </div>
      </LazyLoad>
      <style jsx>{`
        h4 {
          width: 100%;
          text-align: center;
          font-size: 1.3rem;
          margin: 0 0 20px;
        }
        .s8__flex {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          margin: auto;
        }
        .s8__info {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 575px;
          margin: 25px 0;
          opacity: 0;
          animation-name: fadeInOpacity;
          animation-fill-mode: forwards;
          animation-timing-function: ease-in;
          animation-duration: 0.75s;
        }
        .s8__info > span {
          width: auto;
          margin: 0 60px;
        }
        .s8__flex img {
          width: 75px;
        }
        .s8__info > div {
          width: 60%;
        }
        .s8__flex h3 {
          margin: 0;
          color: #ea3d3e;
          font-size: 1.8rem;
        }
        .s8__flex h5 {
          font-size: 1.1rem;
          margin: 0;
          width: 95%;
        }
        @keyframes fadeInOpacity {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @media (max-width: ${THEME.BREAKPOINTS.XS}px) {
          .s8__flex h3 {
            font-size: 1.2rem;
          }
          .s8__flex h5 {
            font-size: 0.85rem;
          }
          .s8__info {
            width: 95%;
            margin: 15px 0;
          }
          .s8__info > span {
            width: auto;
            margin: 0 30px 0 0;
          }
          .s8__flex img {
            width: 45px;
          }
          .s8__info > div {
            width: 70%;
          }
        }
      `}</style>
    </SectionTemplate>
  )
}

const Section9 = () => {
  const icons = [
    {
      icon: 'https://chumley.barstoolsports.com/union/2021/02/02/sponsor-video.40e01d28.png?format=png',
      text: 'Premium Integration',
      link: 'premium-integration'
    },
    {
      icon: 'https://chumley.barstoolsports.com/union/2021/02/02/sponsor-social.4b101a75.png?format=png',
      text: 'Branded Social',
      link: 'branded-social'
    },
    {
      icon: 'https://chumley.barstoolsports.com/union/2021/02/02/sponsor-display.6ce28fe5.png?format=png',
      text: 'Audience Targeting',
      link: 'audience-targeting'
    },
    {
      icon: 'https://chumley.barstoolsports.com/union/2021/02/12/Group-272.2027f111.png?format=png',
      text: 'Experiential',
      link: 'experiential'
    },
    {
      icon: 'https://chumley.barstoolsports.com/union/2021/02/10/branded-content_119.b5d46314.png?format=png',
      text: 'Barstool Creative Services',
      link: 'creative-services'
    },
    {
      icon: 'https://chumley.barstoolsports.com/union/2021/02/12/Group-265.fa463713.png?format=png',
      text: 'Commerce & Licensing',
      link: 'commerce'
    }
  ]

  return (
    <SectionTemplate
      background='#ebebed'
      buttonLink={{
        href: 'partnerships/sponsorships',
        text: 'Learn More'
      }}
    >
      <LazyLoad once>
        <img
          className='s9__img fadeup'
          src='https://chumley.barstoolsports.com/union/2021/02/02/devices.2e5ab84d.png?crop=0.80%2C0.56%2Cx0.15%2Cy0.28%2Csafe'
          alt='s9__img'
        />
        <div className='s9__flex'>
          {icons.map(({ icon, text, link }, idx) => (
            <a
              className='s9__icon fadeup'
              href={`/partnerships/sponsorships/${link}`}
              style={{ animationDelay: `${0.25 * (idx + 1)}s`, color: '#000' }}
              key={idx}
            >
              <img src={icon} alt={text} />
              <h3>{text}</h3>
            </a>
          ))}
        </div>
      </LazyLoad>
      <style jsx>{`
        .s9__flex {
          margin-top: 100px;
          display: flex;
          justify-content: space-evenly;
          width: 80%;
          max-width: 900px;
          margin: 40px auto 0;
        }
        .s9__img {
          display: block;
          width: 80%;
          margin: auto;
          max-width: 1200px;
        }
        .s9__icon {
          width: 100%;
        }
        .s9__icon img {
          display: block;
          width: 65%;
          margin: 0 auto;
        }
        .s9__icon h3 {
          width: 95%;
          text-align: center;
          font-size: 1rem;
          margin: 10px auto 0;
        }
        .fadeup {
          transform: translateY(40px);
          opacity: 0;
          animation-name: fadeup;
          animation-delay: 0.25s;
          animation-duration: 0.75s;
          animation-fill-mode: forwards;
          animation-timing-function: ease-out;
        }
        @keyframes fadeup {
          0% {
            transform: translateY(40px);
            opacity: 0;
          }
          100% {
            transform: translateY(0px);
            opacity: 1;
          }
        }
        @media (max-width: ${THEME.BREAKPOINTS.MD}px) {
          .s9__flex {
            width: 90%;
          }
          .s9__icon h3 {
            font-size: 0.75rem;
          }
        }
        @media (max-width: ${THEME.BREAKPOINTS.XS}px) {
          .s9__img {
            width: 100%;
          }
          .s9__flex {
            flex-wrap: wrap;
            justify-content: space-between;
            width: 95%;
          }
          .s9__icon {
            width: 32%;
            margin: 0 0 20px;
          }
          .s9__icon img {
            width: 70%;
          }
        }
      `}</style>
    </SectionTemplate>
  )
}

const Section10 = () => {
  return (
    <SectionTemplate background='#141e31' styles={{ padding: '2%', width: '100%' }}>
      <div className='s10__wrapper'>
        <img
          src='https://chumley.barstoolsports.com/union/2021/10/12/Partner-images-01.b9c954d6.png?crop=0.99%2C0.75%2Cx0.00%2Cy0.11%2Csafe'
          alt='s10'
        />
        <div className='s10__container'>
          <h2>Proven Ability To Help Our Partners Break Through.</h2>
          <h3>
            At Barstool, we drive business to our partners by creating content that resonates with coveted audiences
          </h3>
          <Link href={'/partnerships/partners'} legacyBehavior>
            <a>
              <button className='btn'>Learn More</button>
            </a>
          </Link>
        </div>
      </div>
      <style jsx>{`
        .s10__wrapper {
          width: 100%;
          max-width: 1200px;
          margin: auto;
          position: relative;
        }
        .s10__wrapper img {
          max-width: 100%;
          opacity: 0.15;
        }
        .s10__container {
          position: absolute;
          color: #fff;
          width: 100%;
          top: 25%;
          text-align: center;
        }
        h2 {
          font-size: 3.5rem;
          width: 70%;
          margin: auto;
          color: ${THEME.COLORS.PRIMARY};
        }
        h3 {
          font-size: 1.25rem;
          width: 50%;
          margin: 20px auto 40px;
        }
        .s10__container .btn {
          margin: 0;
          width: 200px;
          background-color: #c62232;
        }
        @media (max-width: ${THEME.BREAKPOINTS.XL}px) {
          .s10__container {
            top: 20%;
          }
          h2 {
            width: 75%;
          }
          h3 {
            width: 75%;
          }
        }
        @media (max-width: ${THEME.BREAKPOINTS.LG}px) {
          .s10__container {
            top: 15%;
          }
          h2 {
            font-size: 2.35rem;
            width: 70%;
          }
          h3 {
            font-size: 0.9rem;
            width: 70%;
          }
        }
        @media (max-width: ${THEME.BREAKPOINTS.XS}px) {
          .s10__wrapper {
            overflow: hidden;
            display: flex;
            justify-content: center;
          }
          .s10__wrapper img {
            max-width: 200%;
          }
          .s10__container {
            top: 20%;
          }
          h2 {
            font-size: 1.5rem;
            width: 100%;
          }
          h3 {
            font-size: 0.8rem;
            width: 80%;
            margin: 10px auto 20px;
          }
        }
      `}</style>
    </SectionTemplate>
  )
}

const PartnershipsHome = () => {
  usePageView()
  const [videoSource, setVideoSource] = useState({})

  useEffect(() => {
    const assignVideo = async () => {
      try {
        setVideoSource(mapMediaDocToVideojsObject(await MediaStore().findById('uutMN5SSx8FVL8K1grZouF')))
      } catch (e) {
        console.log(e)
      }
    }

    assignVideo()
  }, [])

  return (
    <PartnershipsLayout
      fullScreen
      background='#fff'
      title='Barstool Sports Advertising, Sponsorships & Partnerships - Advertise with Barstool Sports'
      description='Advertise with Barstool Sports today via paid sponsorships & other partnership options.  Barstool Sports advertising reaches consumers with creative, authentic brand integrations across audio, video, social, and live experiences'
    >
      <VideoPlayerLite videoSource={videoSource} />
      <Section2 />
      <Section3 />
      <Section4 />
      <SectionBanner text={'Our Brands'} />
      <img
        className='brandsImage__desktop'
        src='https://chumley.barstoolsports.com/union/2021/10/12/shows-images-horizontal.735bc730.png'
        alt='brands__desktop'
      />
      <img
        className='brandsImage__mobile'
        src='https://chumley.barstoolsports.com/union/2021/10/12/Show_Images_Vertical.6988f3e4.png'
        alt='brands__mobile'
      />
      <Section5 />
      <SectionBanner text={'Our Verticals'} />
      <Section6 />
      <SectionBanner text={'Our Programming'} />
      <Section7 />
      <SectionBanner text={'Sponsorship Opportunities'} />
      <Section8 />
      <Section9 />
      <Section10 />
      <SectionTemplate
        header='Interested In Working With Barstool Sports?'
        subheader='Provide some information so we can get rolling.'
        buttonLink={{
          href: 'https://barstool.typeform.com/to/qXSeBIvZ',
          text: 'Get Started Today'
        }}
      />
      <style jsx>{`
        .brandsImage__desktop,
        .brandsImage__mobile {
          width: 100%;
        }
        .brandsImage__mobile {
          display: none;
        }
        @media (max-width: ${THEME.BREAKPOINTS.XS}px) {
          .brandsImage__desktop {
            display: none;
          }
          .brandsImage__mobile {
            display: block;
          }
        }
      `}</style>
    </PartnershipsLayout>
  )
}

export const getStaticProps = getStaticWrapper()

export default PartnershipsHome
